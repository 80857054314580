import milenaFarber from "src/assets/images/companiesLogos/milenaFarber.png";

export const PLAN_PAGE_TESTIMONIALS: Array<{
  company: string;
  title: string;
  name: string;
  text: string;
  pictureURL: string;
}> = [
  {
    company: "Namogoo",
    title: "Social Media Marketing Manager",
    name: "Milena Farber",
    text: "With Peech, you really don't need to have years of experience in editing videos and graphic design or anything like that. You can get quality videos quickly.",
    pictureURL: milenaFarber,
  },
];

export const VIDEO_URL = "https://storage.googleapis.com/peech-public/assets/plans/plans-video.mp4";

export const POSTER_URL = "https://storage.googleapis.com/peech-public/assets/plans/plans-video-poster.png";

export const SHOULD_SHOW_SEE_MORE_BTN = false;
